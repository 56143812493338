import {
  Block,
  MainContainer,
  MainContainerFullWidth,
} from "../../styles/basicStyles";
import { FC } from "react";
import { useTranslation } from "next-i18next";
import { HomepageType } from "../../models/homepage";
import Banner from "./components/banner";
import Projects from "./components/projects";
import ContactBanner from "../../components/views/contactBanner";
import WhoWeAre from "./components/whoWeAre";
import HowWeCanHelp from "./components/howWeCanHelp";
import ClientsAboutUs from "./components/clientsAboutUs";
import Blog from "./components/blog";
import Metatags from "../../components/metatags";

interface Props {
  data: HomepageType;
}

export const Homepage: FC<Props> = ({ data }) => {
  const { t, i18n: { language } } = useTranslation();
  
  return (
    <>
      <Metatags
        title={data.meta_title && data.meta_title[language]}
        description={data.meta_description && data.meta_description[language]}
        keywords={data.meta_keywords && data.meta_keywords[language]}
        image={data.meta_image?.url}
      />
      <Banner />
      {data?.caseStudies && data?.caseStudies.length > 0 && (
        <MainContainer>
          <Block pt={{ md: 5.75, xxs: 2.563 }} pb={{ md: 8.5, xxs: 3.125 }}>
            <Projects
              title={t("PROJECTS_WE_ARE_PROUD_OF")}
              description={t("EXPLORE_OUR_RECENT_WORK")}
              array={data?.caseStudies}
              type="work"
            />
          </Block>
        </MainContainer>
      )}
      <WhoWeAre companies={data?.companies || []} />
      {data?.blocks && data?.blocks.length > 0 && (
        <MainContainer>
          <HowWeCanHelp blocks={data?.blocks} />
        </MainContainer>
      )}
      {data?.testimonies && data?.testimonies.length > 0 && (
        <ClientsAboutUs testimonies={data.testimonies} />
      )}
      {data?.blogPosts && data?.blogPosts.length > 0 && (
        <Block pt={{ md: 7.313, xxs: 3.75 }} pb={{ md: 5.063, xxs: 4 }}>
          <Blog
            title={t("RECENT_FROM_OUR_BLOG")}
            description={t("CHECK_OUT_OUR_IDEAS_AND_INSIGHTS")}
            array={data?.blogPosts}
          />
        </Block>
      )}
      <MainContainerFullWidth>
        <ContactBanner />
      </MainContainerFullWidth> 
    </>
  );
};

export default Homepage;
