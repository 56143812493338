import { FC, useState } from "react";
import { Box } from "../../../styles/basicStyles";
import { colors } from "../../../styles/colors";
import { useTranslation } from "next-i18next";
import { CompanyType } from "../../../models/company";
import { WhoWeAreLine, WhoWeAreCircle, UnderlineText, WhoWeAreWrapper } from './styles';
import Typography from "../../../components/typography";
import Dot from "../../../components/views/dot";
import Carousel from "../../../components/views/carousel";
import VisibilitySensor from "react-visibility-sensor";
import Typewriter from 'typewriter-effect';

interface WhoWeAreProps {
  companies?: CompanyType[];
}

const WhoWeAre: FC<WhoWeAreProps> = ({ companies }) => {
  const [started, setStarted] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleCompaniesCarrousel = () => {
    return companies?.map((e) => {
      /*
      * If a Company has Case Studies and Blog Posts, send to Company detail page
      * If it only has 1 Case Study, send to that detail page
      * If it only has 1 Blog Post, send to that page
      */
      let url = `/company/${e.url}`;
      if(e.case_studies_home?.length === 1 && !e.posts_home?.length) url = `/work/${e.case_studies_home[0].url}`;
      else if(e.posts_home?.length === 1 && !e.case_studies_home?.length) url = `/blog/${e.posts_home[0].url}`;

      return { image: e.logo_home.url, url };
    });
  };

  const checkState = (isVisible: boolean) => {
    if(isVisible && !started) setStarted(true);
  };

  return (
    <VisibilitySensor onChange={checkState} delayedCall partialVisibility>
      <WhoWeAreWrapper
        pt={{ md: 7.625, xxs: 3.25 }}
        pb={{ md: 7, xxs: 5.063 }}
        fJustify="center"
        fAlign="center"
        fDirection="column"
        position='relative'
        started={started}
      >
        <WhoWeAreLine started={started}>
          <svg xmlns="http://www.w3.org/2000/svg" width="179" height="352" viewBox="0 0 179 352" fill="none">
            <path d="M290 8.5C259.309 -6.03787 224.22 10.4214 197.267 25.5824C160.145 46.4637 32.3201 194.812 12.5296 232.194C-2.63972 260.847 -5.24565 285.069 30.8321 294.422C61.5173 302.378 96.9461 291.499 122.209 273.815C127.308 270.246 148.946 254.582 137.8 247.514C125.893 239.963 112.526 247.558 102.822 254.835C81.5914 270.758 80.3055 282.482 69.5 306C59.1594 328.506 63.2394 341.949 91.5 347.5C136.827 356.403 179.703 327.589 220.838 313.878" stroke="#64FFE3" strokeOpacity="0.9" strokeWidth="4" strokeLinecap="round"/>
          </svg>
        </WhoWeAreLine>
        <WhoWeAreCircle started={started} />
        <Box
          pl={1.375}
          pr={1.375}
          fJustify="center"
          fAlign="center"
          fDirection="column"
          position='relative'
          zIndex={2}
        >
          <Box fDirection="column" maxW={58}>
            <Typography
              as='h2'
              fSize={{ md: 2.813, xxs: 1.688 }}
              lHeight={{ md: 3.375, xxs: 2 }}
              fWeight={700}
              style={{ color: colors.white }}
            >
              {t("WHO_WE_ARE")}
              <Dot
                w={{ md: 0.875, xxs: 0.5 }}
                h={{ md: 0.875, xxs: 0.5 }}
                ml={0.375}
                bgColor={started ? colors.lightBlue : '#ffffff'}
              />
            </Typography>
            <Typography
                mt={{ md: 2.438, xxs: 3.063 }}
                fSize={{ sm: 1.875, xxs: 1.375 }}
                lHeight={{ sm: 3.375, xxs: 2.625 }}
                fWeight={600}
                style={{ color: colors.white }}
              >
                {t('CODEPOINT_IS_AN_AGENCY')}&nbsp;
                <UnderlineText id="underlineText">
                  <Typewriter
                    options={{
                      strings: [t('CREATES'), t('DEVELOPS'), t('DESIGNS'), t('EMPOWERS'), t('BUILDS')],
                      autoStart: started,
                      loop: true
                    }}
                  />
                </UnderlineText>
                &nbsp;{t('CODEPOINT_IS_AN_AGENCY_2')}
            </Typography>
            <Typography
              fSize={{ sm: 1.875, xxs: 1.375 }}
              lHeight={{ sm: 3.375, xxs: 2.625 }}
              fWeight={600}
              style={{ color: colors.white }}
            >
              {t('CODEPOINT_IS_AN_AGENCY_3')}
            </Typography>
          </Box>
        </Box>
        {companies && companies?.length > 0 && (
          <>
            <Typography
              as='div'
              mt={{ md: 6.438, xxs: 9.25 }}
              mb={{ md: 2.688, xxs: 1.75 }}
              tAlign="center"
              fSize={{ sm: 1.25, xxs: 1.125 }}
              lHeight={{ sm: 1.375, xxs: 1.5 }}
              fWeight={600}
              style={{ color: 'rgba(83, 83, 83, 0.8)' }}
              position='relative'
              zIndex={2}
            >
              {t("COMPANIES_THAT_TRUST_US")}
            </Typography>
            <Carousel array={handleCompaniesCarrousel() || []} />
          </>
        )}
      </WhoWeAreWrapper>
    </VisibilitySensor>
  );
};

export default WhoWeAre;
