import { FC } from "react";
import { DotWrapper } from "./styles";

interface DotProps {
  bgColor?: string;
  h?: number;
  w?: number;
}

const Dot: FC<DotProps & any> = ({ bgColor, w, h, ...forwardProps }) => {
  return (
    <DotWrapper
      display="inline-block"
      w={w ? w : 0.875}
      h={h ? h : 0.875}
      bgColor={bgColor}
      {...forwardProps}
    />
  );
};

export default Dot;
