import { FC } from "react";
import { Block, Box, Image } from "../../../styles/basicStyles";
import { EmblaCarousel } from "./styles";
import { colors } from "../../../styles/colors";
import { useRouter } from "next/router";
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import Autoplay from "embla-carousel-autoplay";
import Typography from "../../typography";
import useEmblaCarousel from 'embla-carousel-react';

interface Item {
  image: string;
  text?: string;
  url?: string;
}

interface BaseCarouselProps {
  array: Item[];
}

const BaseCarousel: FC<BaseCarouselProps> = ({ array }) => {
  const router = useRouter();

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, skipSnaps: true }, [Autoplay({ delay: 3000 }), WheelGesturesPlugin()]);

  const mouseEnter = () => emblaApi?.plugins()?.autoplay?.stop();

  const mouseLeave = () => emblaApi?.plugins()?.autoplay?.play();

  return (
    <Block w='100%'>
      <EmblaCarousel onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <div ref={emblaRef}>
          <div className="embla__container">
            {
              array.map((elem, index) =>
              <div className="embla__slide" key={index}>
                <Box 
                  fAlign='center' 
                  onClick={() => elem.url ? router.push(`${elem.url}`) : undefined}
                  cursor={elem.url ? 'pointer' : 'initial'} 
                >
                  <Image
                    loading='lazy'
                    src={elem.image} 
                    alt={elem.text || 'logo'} 
                    title={elem.text || ''} 
                    w='auto'
                    h='auto'
                    maxW={7.5}
                    maxH={3}
                  />
                  {
                    elem?.text &&
                    <Typography
                      fSize={{ md: 1.375, xxs: 1.25 }}
                      lHeight={{ md: 1.625, xxs: 1.5 }}
                      fWeight={600}
                      color={colors.chineseBlack}
                      ml={{ md: 1.125, xxs: 0.938 }}
                    >
                      {elem.text}
                    </Typography>
                  }
                </Box>
              </div>
            )}
          </div>
        </div>
      </EmblaCarousel>
    </Block>
  );
};

export default BaseCarousel;
