import {
  Block,
  Box,
  EllipsisText,
  Image,
  MainContainer,
} from "../../../styles/basicStyles";
import { Carousel, Embla } from "@mantine/carousel";
import { useTranslation } from "next-i18next";
import { FC, useState } from "react";
import { Testimony } from "../../../models/global";
import { colors } from "../../../styles/colors";
import { ClientsLine, ClientsCircle1, ClientsCircle2, ClientsWrapper } from "./styles";
import Typography from "../../../components/typography";
import Button from "../../../components/button";
import Dot from "../../../components/views/dot";
import Icon from "../../../components/icon";
import VisibilitySensor from "react-visibility-sensor";
import Fade from "react-reveal/Fade";

interface ClientsAboutUsProps {
  testimonies: Testimony[];
}

const ClientsAboutUs: FC<ClientsAboutUsProps> = ({ testimonies }) => {
  const [embla, setEmbla] = useState<Embla>();
  const [started, setStarted] = useState<boolean>(false);
  const { t, i18n: { language } } = useTranslation();

  const checkState = (isVisible: boolean) => {
    if(isVisible && !started) setStarted(true);
  };

  return (
    <VisibilitySensor onChange={checkState} delayedCall partialVisibility>
      <ClientsWrapper
        pt={{ md: 5.375, xxs: 3.438 }}
        pb={{ md: 8.75, xxs: 4.125 }}
        h={{ md: 41.563, xxs: "auto" }}
        position="relative"
        started={started}
      >
        <ClientsLine started={started}>
          <svg xmlns="http://www.w3.org/2000/svg" width="144" height="497" viewBox="0 0 144 497" fill="none">
            <path d="M-27.7074 3.99207C-28.2015 -2.92481 -28.427 10.1952 -27.7074 12.6688C-25.306 20.9239 -23.4034 29.5692 -20.3864 37.6145C-14.2734 53.916 -7.37159 69.8104 1.03435 85.0656C15.5212 111.357 25.632 140.304 38.724 167.495C53.4381 198.055 66.6072 228.182 91.3269 252.364C101.106 261.931 117.498 269.867 131.457 264.024C144.761 258.455 141.813 237.606 140.134 226.063C136.22 199.157 121.966 174.816 91.3269 174.816C62.0045 174.816 39.7943 198.331 19.4725 216.03C-22.1209 252.257 -52.5749 302.196 -78.9546 349.978C-103.828 395.032 -118 443.165 -118 494.5" stroke="#64FFE3" strokeOpacity="0.9" strokeWidth="4" strokeLinecap="round"/>
          </svg>
        </ClientsLine>
        <ClientsCircle1 started={started} />
        <ClientsCircle2 started={started} />
        <Box fJustify="center" fAlign="center" fDirection="column" position='relative' zIndex={3}>
          <MainContainer>
            <Box fDirection="column" position="relative">
              <Typography
                as='h2'
                fSize={{ md: 2.813, xxs: 1.688 }}
                lHeight={{ md: 3.375, xxs: 2 }}
                fWeight={700}
                style={{ color: colors.white }}
              >
                {t("CLIENTS_ABOUT_US")}
                <Dot
                  w={{ md: 0.875, xxs: 0.5 }}
                  h={{ md: 0.875, xxs: 0.5 }}
                  ml={0.375}
                  bgColor={started ? colors.lightBlue : '#ffffff'}
                />
              </Typography>
              <Typography
                tAlign="center"
                fSize={{ md: 1.25, xxs: 1 }}
                lHeight={{ md: 1.5, xxs: 1.188 }}
                fWeight={400}
                mt={0.5}
                color={colors.white}
              >
                {t("WHAT_CLIENTS_THINK_ABOUT_US")}
              </Typography>
              <Carousel
                getEmblaApi={setEmbla}
                withControls={false}
                slideSize="100%"
                w="100%"
                loop
                slidesToScroll={1}
                withIndicators={false}
              >
                {testimonies.map((item, index) => (
                  <Carousel.Slide key={index}>
                    <Block
                      display="flex"
                      mt={{ md: 4.25, xxs: 3.438 }}
                      fJustify="center"
                      minW="100%"
                      fDirection={{ md: "row", xxs: "column" }}
                    >
                      <Box
                        w="unset"
                        fAlign={{ md: "flex-start", xxs: "center" }}
                        mb={{ md: 0, xxs: 3.875 }}
                      >
                        <Fade bottom delay={400}>
                          <Image
                            loading='lazy'
                            src={item.image.url}
                            h={{ md: 12.5, xxs: 5 }}
                            w={{ md: 12.5, xxs: 5 }}
                            bRadius={0.25}
                            mr={{ md: 3.625, xxs: 1.25 }}
                            alt='author'
                          />
                        </Fade>
                        <Box
                          display={{ md: "none", xxs: "flex" }}
                          fAlign="flex-start"
                          fWrap="wrap"
                          fDirection="column"
                        >
                          <Typography
                            fSize={1.563}
                            lHeight={2}
                            fWeight={600}
                            color={colors.white}
                          >
                            {item.name}
                          </Typography>
                          <Fade bottom delay={400}>
                            <Block display="flex" fAlign="center" fWrap='wrap'>
                              <Typography
                                fSize={1.25}
                                lHeight={2}
                                fWeight={400}
                                style={{ color: colors.white80percent}}
                                mr={0.688}
                              >
                                {item.job[language]} {t("AT")}
                              </Typography>
                              <Image 
                                loading='lazy'
                                src={item.company.logo_detail.url} 
                                h={1.5} 
                                alt='logo' 
                              />
                            </Block>
                          </Fade>
                        </Box>
                      </Box>
                      <Box fDirection="column" maxW={{ md: 42.125, xxs: "100%" }}>
                        <Block display={{ md: "flex", xxs: "none" }}>
                          <EllipsisText
                            ellipsisColor={colors.white}
                            lines={5}
                            h={11.25}
                            position="relative"
                          >
                            <Typography
                              fSize={1.438}
                              lHeight={2.25}
                              fWeight={350}
                              color={colors.white}
                              fStyle='italic'
                            >
                              {item.description[language]}
                            </Typography>
                          </EllipsisText>
                        </Block>
                        <Block display={{ md: "none", xxs: "flex" }}>
                          <EllipsisText
                            ellipsisColor={colors.white}
                            lines={6}
                            h={14}
                          >
                            <Typography
                              fSize={1.438}
                              lHeight={2.25}
                              fWeight={350}
                              color={colors.white}
                              fStyle='italic'
                            >
                              {item.description[language]}
                            </Typography>
                          </EllipsisText>
                        </Block>
                        <Box
                          display={{ md: "flex", xxs: "none" }}
                          fAlign="flex-start"
                          mt={2.188}
                          fDirection="column"
                        >
                          <Typography
                            fSize={1.563}
                            lHeight={2}
                            fWeight={600}
                            color={colors.white}
                          >
                            {item.name}
                          </Typography>
                          <Fade bottom delay={400}>
                            <Box fAlign="center" mt={0.75}>
                              <Typography
                                fSize={1.25}
                                lHeight={2}
                                fWeight={400}
                                style={{ color: '#757575' }}
                                mr={0.688}
                              >
                                {item.job[language]} {t("AT")}
                              </Typography>
                              <Image
                                loading='lazy'
                                src={item.company.logo_detail.url}
                                h={1.8125}
                                alt='logo'
                              />
                            </Box>
                          </Fade>
                        </Box>
                      </Box>
                    </Block>
                  </Carousel.Slide>
                ))}
              </Carousel>
              <Box
                display={{ md: "flex", xxs: "none" }}
                w="unset"
                position="absolute"
                bottom={0}
                right={0}
              >
                <Fade bottom delay={400}>
                  <Button
                    noIconMargin
                    padding={0.974}
                    bTopLeftRadius={0.25}
                    bBottomLeftRadius={0.25}
                    bTopRightRadius={0}
                    bBottomRightRadius={0}
                    variant="secondary"
                    textColor={colors.white}
                    bgColor={colors.lightBlue}
                    icon="outlined_chevronLeft"
                    onClick={() => embla?.scrollPrev()}
                  />
                  <Button
                    noIconMargin
                    padding={0.974}
                    bTopLeftRadius={0}
                    bBottomLeftRadius={0}
                    bTopRightRadius={0.25}
                    bBottomRightRadius={0.25}
                    variant="secondary"
                    textColor={colors.white}
                    bgColor={colors.lightBlue}
                    icon="outlined_chevronRight"
                    onClick={() => embla?.scrollNext()}
                  />
                </Fade>
              </Box>
            </Box>
            <Box display={{ md: "none", xxs: "flex" }}>
              <Fade bottom delay={400}>
                <Button
                  noIconMargin
                  padding={0.974}
                  bTopLeftRadius={0.25}
                  bBottomLeftRadius={0.25}
                  bTopRightRadius={0}
                  bBottomRightRadius={0}
                  variant="secondary"
                  textColor={colors.white}
                  bgColor={colors.lightBlue}
                  icon="outlined_chevronLeft"
                  onClick={() => embla?.scrollPrev()}
                />
                <Button
                  noIconMargin
                  padding={0.974}
                  bTopLeftRadius={0}
                  bBottomLeftRadius={0}
                  bTopRightRadius={0.25}
                  bBottomRightRadius={0.25}
                  variant="secondary"
                  textColor={colors.white}
                  bgColor={colors.lightBlue}
                  icon="outlined_chevronRight"
                  onClick={() => embla?.scrollNext()}
                />
              </Fade>
            </Box>
          </MainContainer>
        </Box>
      </ClientsWrapper>
    </VisibilitySensor>
  );
};

export default ClientsAboutUs;
