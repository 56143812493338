import { Block, Box } from "../../../styles/basicStyles";
import { colors } from "../../../styles/colors";
import { useTranslation } from "next-i18next";
import { BannerWrapper, BannerCircle1, BannerCircle2, BannerCircle3, BannerTitle, BannerLine } from "./styles";
import { isMobile } from 'react-device-detect';
import { useRouter } from "next/router";
import React, { FC, useEffect, useRef, useState } from "react";
import Typography from "../../../components/typography";
import Button from "../../../components/button";
import gsap from "gsap";
import Fade from 'react-reveal/Fade';

const Banner: FC = () => {
  const [inside, _setInside] = useState(false);
  const [circle, _setCircle] = useState('.circle1');
  const { t } = useTranslation();
  const router = useRouter();

  const circleRef = useRef(circle);
  const setCircle = (data: any) => {
    circleRef.current = data;
    _setCircle(data);
  };

  const insideRef = useRef(inside);
  const setInside = (data: any) => {
    insideRef.current = data;
    _setInside(data);
  };

  useEffect(() => {
    if(!isMobile) {
      window.addEventListener('mousemove', moveCircle);
      window.addEventListener('scroll', stickCircle);
      return () => {
        window.removeEventListener('mousemove', moveCircle);
        window.removeEventListener('scroll', stickCircle);
      };
    }
  }, [isMobile]);

  const moveCircle = (e: any) => {
    if(!isMobile && insideRef.current && circleRef.current) {
      const circleElem = document.querySelector(circleRef.current);

      gsap.to(circleElem, { 
        duration: 7,
        left: e.clientX - 50,
        top: e.clientY - 150 + window.pageYOffset,
        ease: "elastic(1, 0.2)"
      });
    }
  };

  const stickCircle = (e: any) => {
    if(!isMobile && insideRef.current && circleRef.current) {
      const circleElem = document.querySelector(circleRef.current);

      gsap.set(circleElem, {
        left: e.pageX,
        top: e.pageY
      });
    }
  };

  return (
    <BannerWrapper
      pt={{ md: 12.5, xxs: 14.375 }}
      pb={{ md: 13.5, xxs: 9.25 }}
      bgColor={colors.black}
      fDirection="column"
      fAlign="center"
      onMouseEnter={() => !isMobile && setInside(true)} 
      onMouseLeave={() => !isMobile && setInside(false)}
    >
      <BannerCircle1 onMouseEnter={() => !isMobile && setCircle('.circle1')} className="circle1" />
      <BannerCircle2 onMouseEnter={() => !isMobile && setCircle('.circle2')} className="circle2" />
      <BannerCircle3 onMouseEnter={() => !isMobile && setCircle('.circle3')} className="circle3" />
      <BannerTitle fDirection="column" fAlign="center">
        <Fade bottom>
          <Typography
            as='h1'
            tAlign='center'
            fSize={{ md: 4.063, xxs: 2 }}
            lHeight={{ md: 4.813, xxs: 2.75 }}
            fWeight={700}
            style={{ color: colors.white }}
            className='bannerText'
          >
            {t('WE_CREATE_PRODUCTS')}
          </Typography>
        </Fade>
        <Fade bottom delay={300}>
          <Box fAlign='center'>
            <Typography
              as='h1'
              fSize={{ md: 4.063, xxs: 2 }}
              lHeight={{ md: 4.813, xxs: 2.75 }}
              fWeight={700}
              style={{ color: colors.white }}
              className='bannerText'
            >
              {t('WITH')}&nbsp;
            </Typography>
            <Block position='relative'>
              <Typography
                as='h1'
                fSize={{ md: 4.063, xxs: 2 }}
                lHeight={{ md: 4.813, xxs: 2.75 }}
                fWeight={700}
                style={{ color: colors.white }}
                className='bannerText'
              >
                {t('PEOPLE')}
              </Typography>
              <BannerLine>
                <svg width="198" height="23" viewBox="0 0 198 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 14.0303L66.6228 2.595C69.7738 2.03741 71.9437 5.67011 69.959 8.18024V8.18024C68.1261 10.4983 69.8342 13.9017 72.7882 13.8173L113.541 12.6529C115.457 12.5982 116.567 14.8019 115.383 16.3085V16.3085C114.069 17.9812 115.489 20.3977 117.59 20.064L195.636 7.66667" stroke="#816CFF" strokeWidth="4" strokeLinecap="round"/>
                </svg>
              </BannerLine>
            </Block>
            <Typography
              as='h1'
              fSize={{ md: 4.063, xxs: 2 }}
              lHeight={{ md: 4.813, xxs: 2.75 }}
              fWeight={700}
              style={{ color: colors.white }}
              className='bannerText'
            >
              &nbsp;{t('IN_MIND')}
            </Typography>
          </Box>
        </Fade>
      </BannerTitle>
      <Block position='relative' zIndex={4}>
        <Fade bottom delay={600}>
          <Button
            mt={{ md: 4, xxs: 3.75 }}
            text={t("SEE_YOU_AT_WORK")}
            size="md"
            variant="tertiary"
            textColor={colors.white}
            bgColor={colors.purple}
            icon="outlined_arrowRight"
            onClick={() => router.push("/work")}
          />
        </Fade>
      </Block>
    </BannerWrapper>
  );
};

export default Banner;
