import {
  HowCanWeHelpWrapper,
  HowWeCanHelpIconWrapper,
  HowWeCanHelpItemWrapper
} from "./styles";
import { Block as BlockType } from "../../../models/global";
import { HowWeCanHelpIconsProps } from "../../../models/homepage";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { Block, Box } from "../../../styles/basicStyles";
import { colors } from "../../../styles/colors";
import { useRouter } from "next/router";
import Typography from "../../../components/typography";
import TextButton from "../../../components/textButton";
import Icon from "../../../components/icon";
import Dot from "../../../components/views/dot";

const Icons: HowWeCanHelpIconsProps[] = [
  {
    _id: 0,
    icon: "outlined_productStrategy",
  },
  {
    _id: 1,
    icon: "outlined_uxUiDesign",
  },
  {
    _id: 2,
    icon: "outlined_development",
  },
  {
    _id: 3,
    icon: "outlined_outsourcing",
  },
];

interface HowWeCanHelpProps {
  blocks: BlockType[];
}

const HowWeCanHelp: FC<HowWeCanHelpProps> = ({ blocks }) => {
  const [offset, setOffset] = useState<number>(0);
  const [highlighted, setHighlighted] = useState<number>(-1);
  const containerRef = useRef<any>(null);
  const title = useRef<any>(null);
  const elementsRef = useRef<(HTMLDivElement | null)[]>([]);
  const containerOffsetTop = containerRef?.current?.offsetTop || 2460;
  const { t, i18n: { language } } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.scrollY);

      const indexInView = elementsRef.current.findIndex((element) => {
        if(element) {
          const rect = element.getBoundingClientRect();
          return rect.top >= window.innerHeight / 4 && rect.bottom <= window.innerHeight;
        }
        return false;
      });

      if(indexInView !== -1) setHighlighted(indexInView);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HowCanWeHelpWrapper
      ref={containerRef}
      pt={{ md: 6.938, xxs: 3.75 }}
      pb={{ md: 7.313, xxs: 3.75 }}
    >
      <Box
        fDirection="column"
        fJustify="space-between"
        w="50%"
        display={{ md: "flex", xxs: "none" }}
      >
        <Box position="relative" h="100%">
          <Box
            position={
              offset < containerOffsetTop ||
              offset > containerOffsetTop + title?.current?.offsetTop - 402
                ? "absolute"
                : "fixed"
            }
            top={
              offset < containerOffsetTop ||
              offset > containerOffsetTop + title?.current?.offsetTop - 402
                ? undefined
                : "7rem"
            }
            bottom={
              offset <= containerOffsetTop + title?.current?.offsetTop - 402
                ? undefined
                : "7rem"
            }
          >
            <Box fDirection="column" mb={{ md: 0, xxs: 4.125 }} mr={1}>
              <Typography
                as="h2"
                fSize={{ md: 2.813, xxs: 1.688 }}
                lHeight={{ md: 3.375, xxs: 2 }}
                fWeight={700}
              >
                {t("HOW_WE_CAN_HELP")}
                <Dot
                  w={{ md: 0.875, xxs: 0.5 }}
                  h={{ md: 0.875, xxs: 0.5 }}
                  ml={0.375}
                />
              </Typography>
              <Typography
                as="div"
                fSize={{ md: 1.438, xxs: 1 }}
                lHeight={{ md: 2.375, xxs: 1.625 }}
                fWeight={400}
                color={{ md: colors.black, xxs: colors.coolGrey }}
                mt={{ md: 1.563, xxs: 1 }}
                maxW={{ md: 29, xxs: "100%" }}
              >
                {t("HOW_WE_CAN_HELP_DESCRIPTION")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          ref={title}
          fDirection="column"
          display={{ md: "flex", xxs: "none" }}
        >
          <Typography
            fSize={1.5}
            lHeight={1.813}
            fWeight={600}
            color={colors.black}
          >
            {t("HOW_WE_ARE_DOING_IT")}
          </Typography>
          <TextButton
            mt={0.5}
            text={t("LEARN_MORE_ABOUT_OUR_PROCESS")}
            icon="outlined_arrowRight"
            onClick={() => router.push('/process')}
          />
        </Box>
      </Box>
      <Box fDirection="column" w="100%" display={{ md: "none", xxs: "flex" }}>
        <Typography
          as="div"
          fSize={{ md: 2.813, xxs: 1.688 }}
          lHeight={{ md: 3.375, xxs: 2 }}
          fWeight={700}
        >
          {t("HOW_WE_CAN_HELP")}
          <Dot
            w={{ md: 0.875, xxs: 0.5 }}
            h={{ md: 0.875, xxs: 0.5 }}
            ml={0.375}
          />
        </Typography>
        <Typography
          as="div"
          fSize={{ md: 1.438, xxs: 1 }}
          lHeight={{ md: 2.375, xxs: 1.625 }}
          fWeight={400}
          color={{ md: colors.black, xxs: colors.coolGrey }}
          mt={{ md: 1.563, xxs: 1 }}
          maxW={{ md: 29.75, xxs: "100%" }}
        >
          {t("HOW_WE_CAN_HELP_DESCRIPTION")}
        </Typography>
      </Box>
      <Box fAlign="center" fDirection="column" w={{ md: "50%", xxs: "100%" }}>
        {blocks.map((item, index) => (
          <HowWeCanHelpItemWrapper
            fDirection="column"
            mt={{ md: 4.938, xxs: 3.375 }}
            padding={{ md: 2, xxs: 0.5 }}
            maxW={{ md: 34.75, xxs: "unset" }}
            key={index}
            index={index}
            highlighted={highlighted}
            ref={(elem: any) => (elementsRef.current[index] = elem)}
          >
            <Box fAlign="center">
              <HowWeCanHelpIconWrapper
                index={index}
                highlighted={highlighted}
              >
                <Icon icon={Icons[index].icon} className="icon" size={2.7} />
              </HowWeCanHelpIconWrapper>
              <Typography
                ml={1.063}
                fDirection="row"
                as="div"
                fSize={{ md: 1.625, xxs: 1.25 }}
                lHeight={{ md: 1.938, xxs: 1.5 }}
                fWeight={700}
              >
                {item?.title[language]}
              </Typography>
            </Box>
            <Block
              w="100%"
              maxW={{ md: 35.375, xxs: "100%" }}
              bgColor={{
                md: highlighted === index ? colors.white : colors.greyGoose,
                xxs: colors.greyGoose,
              }}
              h={0.063}
              mt={{ md: 2, xxs: 1.875 }}
              mb={{ md: 1.25, xxs: 1 }}
            />
            <Typography
              fDirection="row"
              as="div"
              fSize={{ md: 1.25, xxs: 1 }}
              lHeight={{ md: 1.625, xxs: 1.75 }}
              fWeight={400}
              maxW={{ md: 27.875, xxs: "100%" }}
              dangerouslySetInnerHTML={{ __html: item?.description[language] }}
            />
          </HowWeCanHelpItemWrapper>
        ))}
      </Box>
      <Box fDirection="column" display={{ md: "none", xxs: "flex" }} mt={5.438}>
        <Typography
          fSize={1.375}
          lHeight={1.688 }
          fWeight={600}
          color={colors.black}
        >
          {t("HOW_WE_ARE_DOING_IT")}
        </Typography>
        <TextButton
          mt={0.375}
          text={t("LEARN_MORE_ABOUT_OUR_PROCESS")}
          icon="outlined_arrowRight"
          onClick={() => router.push('/process')}
        />
      </Box>
    </HowCanWeHelpWrapper>
  );
};

export default HowWeCanHelp;
