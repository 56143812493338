import { Box, transitionsDelay } from '../../../styles/basicStyles';
import { breakpoints } from '../../../styles/breakpoints';
import styled from 'styled-components';

export const EmblaCarousel = styled.div` 
  & .embla__container {
    display: flex;
  }

  & .embla__slide {
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;

    & img {
      transition: transform ${transitionsDelay} linear;
    }

    &:hover {
      & img {
        transform: scale(1.1);
      }
    }

    @media ${breakpoints.md} { 
      flex: 0 0 25%;
    }

    @media ${breakpoints.sm} { 
      flex: 0 0 33.333%;
    }

    @media ${breakpoints.xs} { 
      flex: 0 0 100%;

      & > div {
        justify-content: center;
      }
    }
  }
`;
