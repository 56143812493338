import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetHomepage } from '../api/requests/pages';
import { HomepageType } from '../models/homepage';
import Homepage from '../views/homepage';

interface Props {
  data: HomepageType;
}

function Index({ data }: Props) {
  return (
    <Homepage data={data} />
  );
}

export async function getServerSideProps({ locale }: any) {
  const { data, success } = await GetHomepage();

  return {
    props: {
      ...(await serverSideTranslations(locale, ['translation'])),
      data: success ? data : {}
    }
  };
}

export default Index;
