import { Block, Box } from "../../../styles/basicStyles";
import { colors } from "../../../styles/colors";
import { FC } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { AnyObject } from "../../../models/generic";
import { ProductType } from "../../../models/work";
import { ProjectWrapper } from "./styles";
import Typography from "../../../components/typography";
import Dot from "../../../components/views/dot";
import TextButton from "../../../components/textButton";
import Card from "../../../components/views/card";

interface ProjectCardProps {
  title: string;
  description: string;
  array: AnyObject;
  type: string;
}

const Projects: FC<ProjectCardProps> = ({
  title,
  description,
  array,
  type
}) => {
  const { t, i18n: { language } } = useTranslation();
  const router = useRouter();

  const handleTags = (types: ProductType[]) => types.map((e) => e.name[language]);

  return (
    <Box fDirection="column" position='relative' zIndex={2}>
      <Box fDirection="column">
        <Typography
          as='h2'
          fSize={{ md: 2.813, xxs: 1.688 }}
          lHeight={{ md: 3.375, xxs: 2 }}
          fWeight={700}
          style={{ color: colors.black }}
        >
          {title}
          <Dot
            w={{ md: 0.875, xxs: 0.5 }}
            h={{ md: 0.875, xxs: 0.5 }}
            ml={0.375}
          />
        </Typography>
        <Typography
          tAlign="center"
          fSize={{ md: 1.25, xxs: 1 }}
          lHeight={{ md: 1.5, xxs: 1.188 }}
          fWeight={400}
          style={{ color: colors.black }}
          mt={0.5}
          mb={{ md: 2.344, xxs: 1 }}
        >
          {description}
        </Typography>
      </Box>
      <ProjectWrapper
        display="flex"
        margin={{ xs: '0 -1.125rem', xxs: 0 }}
      >
        {
          array?.map((elem: AnyObject, index: number) =>
            <Block 
              key={index} 
              w={{ md: '33.333%', xs: '50%', xxs: '100%' }} 
              padding={{ xs: '0 1.125rem', xxs: 0 }}
              mb={{ md: 0, xxs: 2.25 }}
            >
              <Card 
                imagePath={elem?.image?.url}
                title={elem.category ? elem.category.name[language] : elem?.title[language]}
                subtitle={elem?.subtitle ? elem?.subtitle[language] : elem?.title[language]}
                tags={elem?.product_types?.length > 0 ? handleTags(elem?.product_types) : []}
                author={elem.author?.name || ''}
                date={elem.date || ''}
                onClick={() => router.push(`/${type}/${elem.url}`)}
              />
            </Block>
          )
        }
        <Box w="50%" fJustify="center" fAlign='center'>
          <TextButton
            display={{ md: "none", xs: "flex", xxs: "none" }}
            text={
              type === "work" ? t("MORE_PROJECTS") : t("MORE_ARTICLES")
            }
            icon="outlined_arrowRight"
            onClick={() => router.push(`/${type}`)}
          />
        </Box>
      </ProjectWrapper>
      <Box fJustify='center'>
        <TextButton
          display={{ md: "flex", xs: "none", xxs: "flex" }}
          text={type === "work" ? t("MORE_PROJECTS") : t("MORE_ARTICLES")}
          icon="outlined_arrowRight"
          mt={{ xs: 4.125, xxs: 3.125 }}
          onClick={() => router.push(`/${type}`)}
        />
      </Box>
    </Box>
  );
};

export default Projects;
