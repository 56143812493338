import { ButtonWrapper, IconWrapper } from "./styles";
import { FC, MouseEvent } from "react";
import { Box } from "../../styles/basicStyles";
import { colors } from "../../styles/colors";
import Typography from "../typography";
import Icon from "../icon";

interface TextButtonProps {
  text: string;
  icon?: string;
  primaryColor?: string;
  hoveredColor?: string;
  onClick?: (event: MouseEvent) => void;
}

const TextButton: FC<TextButtonProps & any> = ({
  text,
  icon,
  primaryColor = colors.black,
  hoveredColor = colors.purple,
  onClick,
  ...forwardProps
}) => {
  return (
    <ButtonWrapper
      {...forwardProps}
      hoveredColor={hoveredColor}
      onClick={onClick}
    >
      <Box fAlign="center" fJustify="center">
        <Typography
          fSize={{ md: 1.5, xxs: 1.375 }}
          lHeight={{ md: 1.813, xxs: 1.688 }}
          fWeight={600}
          mr={{ md: icon ? 0.75 : 0, xxs: icon ? 0.625 : 0 }}
          color={primaryColor}
        >
          {text}
          {icon && (
            <IconWrapper>
              <Icon
                icon={icon}
                className="icon"
                size={1.313}
                color={primaryColor}
              />
            </IconWrapper>
          )}
        </Typography>
      </Box>
    </ButtonWrapper>
  );
};

export default TextButton;
