import { Box, transitionsDelay } from '../../styles/basicStyles';
import styled from 'styled-components';

export const ButtonWrapper = styled(Box)`
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  width: auto;

  & span{
    transition: color ${transitionsDelay} linear;
  }

  &:after {
    content: '';
    height: 0.25rem;
    width: 0;
    margin-top: 0.25rem;
    transition: width ${transitionsDelay} linear;
    background-color: ${({ hoveredColor }) => hoveredColor};
  }
 
  &:hover {
    &:after {
      width: 100%;
    }

    & span {
      color: ${({ hoveredColor }) => hoveredColor};
    }

    & .icon {
      & svg path[fill] {
        fill: ${({ hoveredColor }) => hoveredColor};
      }

      & svg path[stroke] {
        stroke: ${({ hoveredColor }) => hoveredColor};
      }
    }
  }
`;

export const IconWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.75rem;
`;
