import { Block, Box, extraBreakpoint, transitionsDelay } from '../../../styles/basicStyles';
import { breakpoints } from '../../../styles/breakpoints';
import styled, { css } from 'styled-components';

/************************************ BANNER ************************************/

export const BannerWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
`;

export const BannerCircle1 = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  width: 17.5rem;
  height: 17.5rem;
  border-radius: 50%;
  background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(129, 108, 255, 0.80) 0%, rgba(129, 108, 255, 0.80) 100%);
  filter: blur(7.5rem);
  z-index: 1;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;
`;

export const BannerCircle2 = styled.div`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(100, 255, 227, 0.60) 0%, rgba(100, 255, 227, 0.60) 100%);
  filter: blur(6.25rem);
  z-index: 2;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;
`;

export const BannerCircle3 = styled.div`
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 11.25rem;
  height: 11.25rem;
  border-radius: 50%;
  background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(129, 108, 255, 0.50) 0%, rgba(129, 108, 255, 0.50) 100%);
  filter: blur(5rem);
  z-index: 3;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;
`;

export const BannerTitle = styled(Box)`
  position: relative;
  z-index: 4;

  @media (max-width: ${extraBreakpoint}rem) {
    & .bannerText {
      font-size: 1.75rem;
      line-height: 2.375rem;
    }
  }
`;

export const BannerLine = styled.div`
  position: absolute;
  left: 0;
  bottom: -1.625rem;
  width: 100%;

  & svg {
    width: 100%;
    height: auto;
  }

  & path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation-duration: 2.8s;
    animation-name: dash;
    animation-iteration-count: 1;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }

  @media ${breakpoints.md} {
    bottom: -1.25rem;
  }

  @media (max-width: ${extraBreakpoint}rem) {
    bottom: -1.125rem;
  }
`;

export const ProjectWrapper = styled(Block)`
  flex-flow: row wrap;
  width: calc(100% + 2.25rem);

  @media ${breakpoints.xs} {
    width: 100%;
  }
`;

/************************************ WHO WE ARE ************************************/

export const WhoWeAreWrapper = styled(Box)<{ started: boolean }>`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.chineseBlack};
    transform: scaleX(${({ started }) => started ? 1 : 0});
    transition: transform 1.25s cubic-bezier(0.475, 0.425, 0, 0.995);
    transform-origin: left;
  }
`;

export const WhoWeAreLine = styled.div<{ started: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  ${({ started }) => started ?
    css`
      display: block; 

      & path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation-duration: 2.5s;
        animation-name: dash;
        animation-iteration-count: 1;
        animation-delay: 1.25s;
        animation-fill-mode: forwards;
      }

      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    `
    :
    css`
      display: none;
    `
  }
  
  @media ${breakpoints.lg} {
    top: 45%;

    & svg {
      width: 6.25rem;
      height: auto;
    }
  }

  @media (max-width: ${extraBreakpoint}rem) {
    top: 50%;
  }
`;

export const WhoWeAreCircle = styled.div<{ started: boolean }>`
  position: absolute;
  top: 3.75rem;
  left: 0;
  width: 18.75rem;
  height: 18.75rem;
  border-radius: 50%;
  background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(100, 255, 227, 0.70) 0%, rgba(100, 255, 227, 0.70) 100%);
  filter: blur(9.375rem);
  z-index: 1;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;

  ${({ started }) => started ?
    css`
      display: block; 
      opacity: 0;
      animation-duration: 1.5s;
      animation-name: visible;
      animation-iteration-count: 1;
      animation-delay: 1.25s;
      animation-fill-mode: forwards;

      @keyframes visible {
        to {
          opacity: 1;
        }
      }
    `
    :
    css`
      display: none;
    `
  }

  @media ${breakpoints.md} {
    width: 9.688rem;
    height: 9.688rem;
    background: radial-gradient(50% 50% at 50% 50%, rgba(100, 255, 227, 0.60) 0%, rgba(100, 255, 227, 0.60) 100%);
    filter: blur(3.75rem);
  }
`;

export const UnderlineText = styled.span`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.25rem;
    width: 100%;
    height: 0.25rem;
    background-color: ${({ theme }) => theme.colors.lightBlue};
  }
`;

/************************************ HOW WE CAN HELP ************************************/

export const HowWeCanHelpIconWrapper = styled(Block)<{highlighted: number, index: number}>`
  position: relative;
  margin-left: ${({ highlighted, index }) => highlighted !== index ? '1rem' : '0'};
  transition: margin-left ${transitionsDelay} linear;
  
  &:before {
    content: '';
    position: absolute;
    top: 0.3rem;
    left: -0.875rem;
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 3.125rem;
    background-color: ${({ theme, highlighted, index }) => highlighted !== index ? theme.colors.purple: 'transparent'}; 
    opacity: 0.2;
    transition: background-color ${transitionsDelay} linear;
  }
`;

export const HowWeCanHelpItemWrapper = styled(Box)<{highlighted: number, index: number}>`
  flex-direction: column;
  transition: background-color ${transitionsDelay} linear;
  background-color: ${({ theme, highlighted, index }) => highlighted === index ? theme.colors.lightPurple: 'transparent'}; 
`;

export const HowCanWeHelpWrapper = styled(Block)`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  flex-direction: row;

  @media ${breakpoints.xxs} {
    flex-flow: wrap;
    flex-direction: column;
  }
`;

/************************************ CLIENTS ABOUT US ************************************/

export const ClientsWrapper = styled(Box)<{ started: boolean }>`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.chineseBlack};
    transform: scaleX(${({ started }) => started ? 1 : 0});
    transition: transform 1.25s cubic-bezier(0.475, 0.425, 0, 0.995);
    transform-origin: left;
  }
`;

export const ClientsLine = styled.div<{ started: boolean }>`
  position: absolute;
  left: 0;
  top: 3rem;
  z-index: 1;

  ${({ started }) => started ?
    css`
      display: block; 

      & path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation-duration: 2.5s;
        animation-name: dash;
        animation-iteration-count: 1;
        animation-delay: 1.25s;
        animation-fill-mode: forwards;
      }

      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    `
    :
    css`
      display: none;
    `
  }

  @media ${breakpoints.md} {
    display: none !important;
  }
`;

export const ClientsCircle1 = styled.div<{ started: boolean }>`
  position: absolute;
  top: 5.375rem;
  right: 12.188rem;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(100, 255, 227, 0.40) 0%, rgba(100, 255, 227, 0.40) 100%);
  filter: blur(9.375rem);
  z-index: 2;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;

  ${({ started }) => started ?
    css`
      display: block; 
      opacity: 0;
      animation-duration: 1.5s;
      animation-name: visible;
      animation-iteration-count: 1;
      animation-delay: 1.25s;
      animation-fill-mode: forwards;

      @keyframes visible {
        to {
          opacity: 1;
        }
      }
    `
    :
    css`
      display: none;
    `
  }

  @media ${breakpoints.md} {
    background: radial-gradient(50% 50% at 50% 50%, rgba(100, 255, 227, 0.20) 0%, rgba(100, 255, 227, 0.20) 100%);
    filter: blur(4.688rem);
    top: 19.063rem;
    right: 1.25rem;
  }
`;

export const ClientsCircle2 = styled.div<{ started: boolean }>`
  position: absolute;
  top: 11.625rem;
  right: 2.5rem;
  width: 17.5rem;
  height: 17.5rem;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(129, 108, 255, 0.30) 0%, rgba(129, 108, 255, 0.30) 100%);
  filter: blur(9.375rem);
  z-index: 1;
  // This code is to fix issues with the gradient in IOS
  transform: translateZ(0);
  backface-visibility: hidden;

  ${({ started }) => started ?
    css`
      display: block; 
      opacity: 0;
      animation-duration: 1.5s;
      animation-name: visible;
      animation-iteration-count: 1;
      animation-delay: 1.25s;
      animation-fill-mode: forwards;

      @keyframes visible {
        to {
          opacity: 1;
        }
      }
    `
    :
    css`
      display: none;
    `
  }

  @media ${breakpoints.md} {
    background: radial-gradient(50% 50% at 50% 50%, rgba(129, 108, 255, 0.20) 0%, rgba(129, 108, 255, 0.20) 100%);
    filter: blur(4.688rem);
    top: 25.313rem;
    right: -8.75rem;    
  }
`;

/************************************ BLOG ************************************/

export const BlogLineDesktop = styled.div<{ started: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;

  & svg {
    width: 100%;
    height: auto;
  }

  ${({ started }) => started ?
    css`
      display: block; 

      & path {
        stroke-dasharray: 10000;
        stroke-dashoffset: 10000;
        animation-duration: 10s;
        animation-name: dash;
        animation-iteration-count: 1;
        animation-delay: 0s;
        animation-fill-mode: forwards;
      }

      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    `
    :
    css`
      display: none;
    `
  }

  @media ${breakpoints.xs} {
    display: none !important;
  }
`;

export const BlogLineMobile = styled.div<{ started: boolean, align?: 'top' | 'bottom' }>`
  position: absolute;
  right: 0;
  z-index: 1;
  width: 100%;
  display: none !important;

  ${({ align }) => 
    align === 'top' ?
    css`
      top: 9.375rem;
    `
    :
    align === 'bottom' ?
    css`
      bottom: 20rem;
    `
    :
    null
  }

  & svg {
    width: 100%;
    height: auto;
  }

  ${({ started }) => started ?
    css`
      display: block; 

      & path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation-duration: 5s;
        animation-name: dash;
        animation-iteration-count: 1;
        animation-delay: 0s;
        animation-fill-mode: forwards;
      }

      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    `
    :
    css`
      display: none;
    `
  }

  @media ${breakpoints.xs} {
    display: block !important;
  }
`;
