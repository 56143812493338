import { Box, MainContainer } from "../../../styles/basicStyles";
import { FC, useState } from "react";
import { AnyObject } from "../../../models/generic";
import { BlogLineDesktop, BlogLineMobile } from "./styles";
import VisibilitySensor from "react-visibility-sensor";
import Projects from './projects';

interface BlogProps {
  title: string;
  description: string;
  array: AnyObject[];
}

const Blog: FC<BlogProps> = ({
  title,
  description,
  array
}) => {
  const [started, setStarted] = useState<boolean>(false);

  const checkState = (isVisible: boolean) => {
    if(isVisible && !started) setStarted(true);
  };

  return (
    <VisibilitySensor onChange={checkState} delayedCall partialVisibility>
      <Box position="relative">
        <BlogLineDesktop started={started}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1512" height="527" viewBox="0 0 1512 527" fill="none">
            <path d="M1577.66 11.7303C1567.49 -5.55847 1511.95 5.06707 1494.69 6.98522C1482.5 8.33927 1468.07 11.4316 1457 16.7466C1443.25 23.3488 1426.59 27.2299 1414.16 36.2693C1398.13 47.9296 1357.17 82.6768 1369.28 106.904C1379.48 127.306 1427.17 123.986 1445.88 123.986C1461.2 123.986 1521.87 130.684 1518.69 102.023C1516.42 81.6387 1456.83 84.9405 1443.31 84.9405C1413.57 84.9405 1381.25 104.298 1357.9 121.817C1331.6 141.535 1298.89 162.504 1267.87 173.877C1241.12 183.686 1216.89 201.159 1190.19 211.838C1157.92 224.747 1124.96 235.887 1092.58 248.443C1017.72 277.47 940.586 295.558 861.015 307.011C788.586 317.437 705.828 333.203 635.148 309.994C614.504 303.216 594.233 297.794 574.41 288.709C546.15 275.756 516.145 275.125 485.474 275.287C444.507 275.504 382.308 287.098 356.136 323.009C339.08 346.411 335.611 377.839 317.904 401.642C298.376 427.893 272.99 447.197 246.591 466.176C205.601 495.646 173.441 514.141 121.592 514.44C98.6175 514.573 75.1694 515.759 52.4488 511.864C41.8781 510.052 31.5447 508.042 20.7244 508.746C4.6563 509.792 0.409304 515.645 -11 524.202" stroke="#64FFE3" strokeOpacity="0.9" strokeWidth="4" strokeLinecap="round"/>
          </svg>
        </BlogLineDesktop>
        <BlogLineMobile started={started} align='top'>
          <svg xmlns="http://www.w3.org/2000/svg" width="390" height="214" viewBox="0 0 390 214" fill="none">
            <path d="M-107 202.262C-93.5092 219.564 -19.8349 208.931 3.06571 207.011C19.2317 205.656 38.3743 202.561 53.063 197.242C71.3092 190.635 93.4062 186.751 109.894 177.704C131.163 166.035 185.492 131.261 169.423 107.016C155.891 86.5977 92.6335 89.92 67.8103 89.92C47.4954 89.92 -32.9915 83.2168 -28.767 111.9C-25.7625 132.3 53.2879 128.996 71.2274 128.996C110.68 128.996 153.544 109.623 184.53 92.0908C219.406 72.3577 262.801 51.3721 303.948 39.9901C339.436 30.1734 371.584 12.6873 407 2.00001" stroke="#64FFE3" strokeOpacity="0.9" strokeWidth="4" strokeLinecap="round"/>
          </svg>
        </BlogLineMobile>
        <BlogLineMobile started={started} align='bottom'>
          <svg xmlns="http://www.w3.org/2000/svg" width="390" height="195" viewBox="0 0 390 195" fill="none">
            <path d="M-54.9999 158.121C-29.6739 164.897 -4.8046 170.317 19.5138 179.398C54.184 192.346 90.9944 192.977 128.623 192.815C178.882 192.598 255.188 181.009 287.298 145.111C308.222 121.718 312.477 90.3031 334.201 66.5085C358.158 40.2682 389.302 20.9719 421.688 1.99997" stroke="#64FFE3" strokeOpacity="0.9" strokeWidth="4" strokeLinecap="round"/>
          </svg>
        </BlogLineMobile>
        <MainContainer>
          <Projects 
            title={title}
            description={description}
            array={array}
            type='blog'
          />
        </MainContainer>
      </Box>
    </VisibilitySensor>
  );
};

export default Blog;
